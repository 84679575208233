import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'

const Historia = ({ data }) => (
  <Layout
    pageTitle='Om Hägges'
    backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
  >
    <oma-grid-row class='row row--with-auto-margin'>
      <section className='section section--with-background'>
        <h1 className='page__heading'>Historia</h1>
        <h2 className='section__heading'>
          Hägges - svensk fikatradition sedan 1956
        </h2>
        <p className='section__text'>
          Hägges startades 1956 av de två kusinerna Thage och Torsten Häggström.
          Grundplåten var bland annat en travvinst på 750 kr för att köpa ett
          litet bageri i Domsjö nära dåvarande MODO pappersbruk. De satte ett
          handklaver i pant hos grossisten för att få råd till råvaror.
          Kusinernas idé var att komplettera sina respektive talanger. Thorsten
          tog fram recept på läckra kakor och Thage var en hejare på försäljning
          och medveten marknadsföring. Till att börja med bakades sockerskorpor,
          som kördes ut till butikerna runt Örnsköldsvik i en begagnad Austin
          skåpbil. Försäljningen gick bra och sockerskorporna byttes snart ut
          mot bland annat Mandelkubb, Artillerikaka och TV-tårta.
        </p>
        <p className='section__text'>
          1957 jobbade 15 personer på bageriet och de första säljande
          chaufförerna anställdes. Sakta men säkert expanderade marknaden och de
          nästkommande åren skickades bror, kusiner, grannar och svågrar ut i
          landet för att sälja Hägges kakor. Redan 1959 omsatte Hägges en miljon
          kronor och sortimentet omfattade cirka 60 produkter. I början av
          60-talet utarbetades en ny förpackning, en plastbricka med kakor på,
          överdragen med cellofan. Att leverera kakor till butik i snygga
          förpackningar visade sig vara en revolution i branschen. Tidigare hade
          kakorna gömts under disk, eftersom de låg i papplådor som blev
          flottiga och fula. Men genom att exponera kakorna i snygga påsar i
          guld och lila så ökade försäljningen, till butiksägarens och bagarnas
          stora glädje. Hägges fick arbeta för högtryck för att kunna leverera
          de nya förpackningarna.
        </p>
        <p className='section__text'>
          Genom bra försäljningsargumentation och snygga förpackningar
          etablerades kakorna på fler och fler regioner.
        </p>
        <p className='section__text'>
          Sedan 2010 ägs Hägges av Switsbake och ingår i Givesco Bakery Group.
          Idag bakas Hägges goda fikabröd i Malmköping.
        </p>
      </section>
      <section className='section section--with-background section--with-image-grid'>
        <GatsbyImage
          image={data.buss.childImageSharp.gatsbyImageData}
          alt='Man lämnar av nybakade artiklar till kvinna ur en buss'
        />
        <GatsbyImage
          image={data.baker.childImageSharp.gatsbyImageData}
          alt='Man står vid produktionsbandet och bakar små kakor'
        />
        <GatsbyImage
          image={data.production.childImageSharp.gatsbyImageData}
          alt='Två kvinnor står vid produktionsbandet och bakar'
        />
        <GatsbyImage
          image={data.tasting.childImageSharp.gatsbyImageData}
          alt='En kock och en man i kostym smakar av de nybakade kakorna'
        />
      </section>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "damer-vid-linjen.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    buss: file(relativePath: { eq: "hagges-buss.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    baker: file(relativePath: { eq: "hagges-bagare.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    production: file(relativePath: { eq: "hagges-produktion.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    tasting: file(relativePath: { eq: "hagges-provsmak.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }
`

export default Historia
